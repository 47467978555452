$black: #eaeaea;
$orange: #ffcc00;
$red: #ff0000;
$green: #a4fd9e;

$borderColorDetected: $black;
$borderColorUpdated:  $orange;
$borderColorValidated: $green;

$backgroundColorDetected: $borderColorDetected;
$backgroundColorUpdated: $borderColorUpdated;
$backgroundColorValidated: $borderColorValidated;
